export type Player = "blue" | "yellow";

export const players: Player[] = ["blue", "yellow"];

export const getRandomPlayer = (): Player =>
  players[Math.floor(Math.random() * players.length)];

export const getOtherPlayer = (player: Player): Player =>
  player === "blue" ? "yellow" : "blue";

export const getPlayerName = (player: Player): string =>
  player === "blue" ? "Modrý" : "Žlutý";

export const pyramidLeftSideIndices = [0, 1, 3, 6, 10, 15, 21];
export const pyramidRightSideIndices = [0, 2, 5, 9, 14, 20, 27];
export const pyramidBottomSideIndices = [21, 22, 23, 24, 25, 26, 27];

export const pyramidNeighbors = [
  [1, 2],
  [0, 2, 3, 4],
  [0, 1, 4, 5],
  [1, 4, 6, 7],
  [1, 2, 3, 5, 7, 8],
  [2, 4, 8, 9],
  [3, 7, 10, 11],
  [3, 4, 6, 8, 11, 12],
  [4, 5, 7, 9, 12, 13],
  [5, 8, 13, 14],
  [6, 11, 15, 16],
  [6, 7, 10, 12, 16, 17],
  [7, 8, 11, 13, 17, 18],
  [8, 9, 12, 14, 18, 19],
  [9, 13, 19, 20],
  [10, 16, 21, 22],
  [10, 11, 15, 17, 22, 23],
  [11, 12, 16, 18, 23, 24],
  [12, 13, 17, 19, 24, 25],
  [13, 14, 18, 20, 25, 26],
  [14, 19, 26, 27],
  [15, 22],
  [15, 16, 21, 23],
  [16, 17, 22, 24],
  [17, 18, 23, 25],
  [18, 19, 24, 26],
  [19, 20, 25, 27],
  [20, 26],
];

export type HexagonState = Player | "free" | "lost";
export type PyramidState = HexagonState[];

export const doWeHaveAWinner = (pyramidState: PyramidState): Player | null => {
  return null;
};

import { useState } from "react";
import { getPlayerName, Player, PyramidState } from "../domain/azkviz";
import { getRandomPlayer } from "../domain/azkviz";
import {
  getOtherPlayer,
  HexagonState,
  doWeHaveAWinner,
} from "../domain/azkviz/index";

const getHexagonTopPosition = (row: number) => (row - 1) * 90 + 20;

const getHexagonLeftPosition = (row: number, index: number) =>
  (7 - row) * 55 + index * 110;

const getDefaultHexagonState = (): PyramidState =>
  [...Array(28)].map(() => "free");

const mutatePyramidState = (
  pyramidState: PyramidState,
  index: number,
  newState: HexagonState
): PyramidState => {
  let newPyramidState = [...pyramidState];
  newPyramidState[index] = newState;
  return newPyramidState;
};

function Hexagon({
  index,
  rowNumber,
  positionInRow,
  state,
  onClick,
}: {
  index: number;
  rowNumber: number;
  positionInRow: number;
  state: HexagonState;
  onClick: (index: number) => void;
}) {
  return (
    <div
      className={`hexagon player-${state}`}
      key={index}
      style={{
        top: getHexagonTopPosition(rowNumber) + "px",
        left: getHexagonLeftPosition(rowNumber, positionInRow - 1) + "px",
      }}
      onClick={() => onClick(index)}
    >
      <p className="number">{index + 1}</p>
    </div>
  );
}

export default function AzKviz() {
  const [turn, setTurn] = useState<Player>(getRandomPlayer());
  const [winner, setWinner] = useState<Player | null>(null);
  const [pyramidState, setPyramidState] = useState<PyramidState>(
    getDefaultHexagonState()
  );

  const onHexagonClick = (index: number) => {
    if (winner) {
      alert("Dohráno");
      return;
    }

    if (pyramidState[index] === "free" || pyramidState[index] === "lost") {
      if (pyramidState[index] === "free") {
        setPyramidState(mutatePyramidState(pyramidState, index, turn));
      } else if (pyramidState[index] === "lost") {
        alert("SOUBOJ");
        setPyramidState(
          mutatePyramidState(pyramidState, index, getRandomPlayer())
        );
      }
      setTurn(getOtherPlayer(turn));
      setWinner(doWeHaveAWinner(pyramidState));
    }
  };

  const hexagons = [];
  let hexagonIndex = 0;
  for (let pyramidRow = 1; pyramidRow <= 7; pyramidRow++) {
    for (let position = 1; position <= pyramidRow; position++) {
      hexagons.push(
        <Hexagon
          key={hexagonIndex}
          index={hexagonIndex}
          rowNumber={pyramidRow}
          positionInRow={position}
          state={pyramidState[hexagonIndex]}
          onClick={onHexagonClick}
        />
      );

      hexagonIndex++;
    }
  }

  return (
    <main
      style={{ padding: "1rem" }}
      id="azkviz"
      className={winner ? "won" : ""}
    >
      <h2>AZ Kvíz</h2>
      {winner ? (
        <div id="legend">
          Vyhrál{" "}
          <span className={`player-name player-${winner}`}>
            {getPlayerName(winner)}!!!
          </span>
        </div>
      ) : (
        <div id="legend">
          Hraje{" "}
          <span className={`player-name player-${turn}`}>
            {getPlayerName(turn)}
          </span>
        </div>
      )}

      <div id="pyramid">
        <div id="background" className={`player-${turn}`}></div>
        {hexagons}
      </div>
    </main>
  );
}
